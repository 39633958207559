import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { HomeComponent } from "./pages/home/home.component";
import { AboutUsComponent } from "./pages/about-us/about-us.component";
import { HartaComponent } from "./pages/harti/harta/harta.component";
import { ContactComponent } from "./pages/contact/contact.component";
import { StiriComponent } from "./pages/stiri/stiri.component";
import { InfrastructuraComponent } from "./pages/infrastructura/infrastructura.component";
import { PoliticaConfComponent } from "./pages/politica-conf/politica-conf.component";
import { AutostradaDetComponent } from "./pages/autostrada-det/autostrada-det.component";
import { InsertHelperComponent } from "./pages/tools/insert-helper/insert-helper.component";
import { InteractiveMapComponent } from "./pages/maps/interactive-map/interactive-map.component";
import { PendingHelperComponent } from "./pages/tools/pending-helper/pending-helper.component";
import { EvMapComponent } from "./pages/maps/ev-map/ev-map.component";
import { HartaEvComponent } from "./pages/harti/harta-ev/harta-ev.component";

const routes: Routes = [
  
  { path: "", component: HomeComponent },
  { path: "despre", component: AboutUsComponent},
  { path: "harta", component: HartaComponent},
  { path: "contact", component: ContactComponent},
  { path: "stiri", component: StiriComponent},
  { path: "infrastructura", component: InfrastructuraComponent},
  { path: "pol-conf", component: PoliticaConfComponent},
  { path: "aut/:id", component: AutostradaDetComponent},
  { path: "help/i", component: InsertHelperComponent},
  { path: "help/p", component: PendingHelperComponent},
  { path: "intermap", component: InteractiveMapComponent },
  { path: "ev", component: EvMapComponent},
  { path: "harta-ev", component: HartaEvComponent},
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
