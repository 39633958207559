import { BrowserModule } from "@angular/platform-browser";
import { Injector, NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

// no layouts views
import { IndexComponent } from "./pages/index/index.component";

// components for views and layouts

import { FooterComponent } from "./pages/footer/footer.component";
import { IndexNavbarComponent } from "./pages/header/index-navbar.component";
import { HomeComponent } from './pages/home/home.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { HartaComponent } from './pages/harti/harta/harta.component';
import { ContactComponent } from './pages/contact/contact.component';
import { StiriComponent } from './pages/stiri/stiri.component';
import { InfrastructuraComponent } from './pages/infrastructura/infrastructura.component';
import { PoliticaConfComponent } from './pages/politica-conf/politica-conf.component';
import { environment } from "src/environments/environment";
import { AppInjectorService } from "./app.injector.service";
import { AppMaterialModule } from "./app.material.module";
import { InformationService } from "./services/information.service";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AutostradaDetComponent } from "./pages/autostrada-det/autostrada-det.component";
import { InsertHelperComponent } from "./pages/tools/insert-helper/insert-helper.component";
import { HttpClientModule } from "@angular/common/http";
import { SafePipe } from "./services/safe.pipe";
import { MatExpansionModule } from "@angular/material/expansion";
import { InteractiveMapComponent } from "./pages/maps/interactive-map/interactive-map.component";
import { AutostradaDetDetaliiComponent } from "./pages/autostrada-det-detalii/autostrada-det-detalii.component";
import { PendingHelperComponent } from "./pages/tools/pending-helper/pending-helper.component";
import { EvMapComponent } from "./pages/maps/ev-map/ev-map.component";
import { HartaEvComponent } from "./pages/harti/harta-ev/harta-ev.component";

// Returneza baseUrl
export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

export function getAPIUrl() {
  if (environment.apiUrl) {
    if (!environment.apiUrlRelative) {
      return environment.apiUrl;
    } else {
      return `${window.location.protocol}//${window.location.host}/${environment.apiUrl}`;
    }
  } else {
    return getBaseUrl();
  }
}

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    IndexNavbarComponent,
    IndexComponent,
    HomeComponent,
    AboutUsComponent,
    HartaComponent,
    ContactComponent,
    StiriComponent,
    InfrastructuraComponent,
    PoliticaConfComponent,
    AutostradaDetComponent,
    AutostradaDetDetaliiComponent,
    InsertHelperComponent,
    SafePipe,
    InteractiveMapComponent,
    PendingHelperComponent,
    EvMapComponent,
    HartaEvComponent
  ],
  imports: [BrowserModule, AppRoutingModule, AppMaterialModule, BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatExpansionModule
    ],
  providers: [ InformationService,
    { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] },
    { provide: 'API_URL', useFactory: getAPIUrl, deps: [] }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(injector: Injector) {
    AppInjectorService.setInjector(injector);
  }
}

