import {Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { BackEndAPINewsInsertOptionalParams, News } from 'src/api/lib/models';
import { BaseComponent } from 'src/app/base.component';
import { InformationService } from 'src/app/services/information.service';

@Component({
  selector: 'app-insert-helper',
  templateUrl: './insert-helper.component.html'
})
export class InsertHelperComponent extends BaseComponent implements OnInit, OnDestroy {

  public contactForm: FormGroup = new FormGroup({
      title: new FormControl(''),
      description: new FormControl(''),
      newsDate: new FormControl(''),
      source: new FormControl(''),
      newsLink: new FormControl('')
    });

  @ViewChild('formContactDirective', { static: false }) formContactDirective: FormGroupDirective;
    
  constructor(public informationService: InformationService) {
        super();
    }

    public getStaticResource(key: string) : string{
      return this.informationService.getValue(key);
    }

  ngOnInit(): void {
    this.informationService.StaticResourcesSelect();
  }

  public async insert() {

    const contForm: News = this.contactForm.value as News;

    const filtru: BackEndAPINewsInsertOptionalParams = {
          body: {
            title: contForm.title,
            description: contForm.description,
            newsDate: contForm.newsDate,
            source: contForm.source,
            newsLink: contForm.newsLink
          }
        };

    await this.informationService.NewsInsert(filtru);
    this.contactForm.reset();
    this.formContactDirective.resetForm();
  }

}
