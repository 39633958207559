/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is regenerated.
 */

import { ServiceClientOptions } from "@azure/ms-rest-js";
import * as msRest from "@azure/ms-rest-js";

/**
 * An interface representing ContactMessage.
 */
export interface ContactMessage {
  fullName?: string;
  email?: string;
  message?: string;
  addDate?: Date;
  hasResponse?: boolean;
}

/**
 * An interface representing Highway.
 */
export interface Highway {
  idHighway?: number;
  code?: string;
  name?: string;
  popularName?: string;
}

/**
 * An interface representing HighwayDescription.
 */
export interface HighwayDescription {
  idHighway?: number;
  paragraph?: string;
  paragraphOrder?: number;
  isActive?: boolean;
}

/**
 * An interface representing HighwayDetailed.
 */
export interface HighwayDetailed {
  idHighway?: number;
  code?: string;
  name?: string;
  popularName?: string;
  totalLength?: number;
  openLength?: number;
  executionLength?: number;
  plannedLength?: number;
  openPercentage?: number;
  executionPercentage?: number;
  plannedPercentage?: number;
}

/**
 * An interface representing HighwaySubdivision.
 */
export interface HighwaySubdivision {
  idHighwaySubdivision?: number;
  idHighway?: number;
  subdivisionName?: string;
  subdivisionLength?: number;
  sectionName?: string;
  idHighwayStatus?: number;
  code?: string;
  description?: string;
  color?: string;
  openEstimation?: string;
  openPercentage?: number;
  subdivisionBuilder?: string;
  contractValue?: string;
  contractSignDate?: Date;
  totalExecutionMonths?: number;
  planningMonths?: number;
  executionMonths?: number;
  highwayCode?: string;
  name?: string;
  popularName?: string;
  totalLength?: number;
  executionPercentage?: number;
}

/**
 * An interface representing LoginRequest.
 */
export interface LoginRequest {
  username?: string;
  password?: string;
}

/**
 * An interface representing Utilizator.
 */
export interface Utilizator {
  idUtilizator?: number;
  cod?: string;
  email?: string;
  passwordHash?: string;
}

/**
 * An interface representing LoginResponse.
 */
export interface LoginResponse {
  utilizator?: Utilizator;
  jwt?: string;
}

/**
 * An interface representing News.
 */
export interface News {
  nrElemente?: number;
  idNews?: number;
  title?: string;
  description?: string;
  newsDate?: Date;
  addDate?: Date;
  source?: string;
  newsLink?: string;
  isActive?: boolean;
}

/**
 * An interface representing NewsFiltru.
 */
export interface NewsFiltru {
  rowOffset?: number;
  rowCount?: number;
}

/**
 * An interface representing PendingNews.
 */
export interface PendingNews {
  nrElemente?: number;
  idPendingNews?: number;
  title?: string;
  description?: string;
  newsDate?: Date;
  addDate?: Date;
  source?: string;
  newsLink?: string;
  pendingStatus?: boolean;
}

/**
 * An interface representing PendingNewsSetStatusFiltru.
 */
export interface PendingNewsSetStatusFiltru {
  pendingStatus?: boolean;
  idPendingNews?: number;
}

/**
 * An interface representing RecaptchaRequest.
 */
export interface RecaptchaRequest {
  recaptchaToken?: string;
}

/**
 * An interface representing StaticResource.
 */
export interface StaticResource {
  idResource?: number;
  code?: string;
  value?: string;
  isActive?: boolean;
}

/**
 * An interface representing ValidateTokenResponse.
 */
export interface ValidateTokenResponse {
  claims?: string;
}

/**
 * An interface representing BackEndAPIOptions.
 */
export interface BackEndAPIOptions extends ServiceClientOptions {
  baseUri?: string;
}

/**
 * Optional Parameters.
 */
export interface BackEndAPILoginOptionalParams extends msRest.RequestOptionsBase {
  body?: LoginRequest;
}

/**
 * Optional Parameters.
 */
export interface BackEndAPIValidateTokenOptionalParams extends msRest.RequestOptionsBase {
  token?: string;
}

/**
 * Optional Parameters.
 */
export interface BackEndAPIContactMessageInsertOptionalParams extends msRest.RequestOptionsBase {
  body?: ContactMessage;
}

/**
 * Optional Parameters.
 */
export interface BackEndAPIHighwaysDetailedSelectOptionalParams extends msRest.RequestOptionsBase {
  body?: string;
}

/**
 * Optional Parameters.
 */
export interface BackEndAPIHighwaysSubdivisionsSelectOptionalParams extends msRest.RequestOptionsBase {
  body?: number;
}

/**
 * Optional Parameters.
 */
export interface BackEndAPIHighwaysSubdivisionsGetOptionalParams extends msRest.RequestOptionsBase {
  body?: number;
}

/**
 * Optional Parameters.
 */
export interface BackEndAPIHighwaysDescriptionsSelectOptionalParams extends msRest.RequestOptionsBase {
  body?: number;
}

/**
 * Optional Parameters.
 */
export interface BackEndAPIMapSectionsSelectJsonOptionalParams extends msRest.RequestOptionsBase {
  body?: string;
}

/**
 * Optional Parameters.
 */
export interface BackEndAPIMapSectionsSelectGeoOptionalParams extends msRest.RequestOptionsBase {
  body?: string;
}

/**
 * Optional Parameters.
 */
export interface BackEndAPINewsSelectOptionalParams extends msRest.RequestOptionsBase {
  body?: NewsFiltru;
}

/**
 * Optional Parameters.
 */
export interface BackEndAPINewsGetOptionalParams extends msRest.RequestOptionsBase {
  body?: number;
}

/**
 * Optional Parameters.
 */
export interface BackEndAPINewsInsertOptionalParams extends msRest.RequestOptionsBase {
  body?: News;
}

/**
 * Optional Parameters.
 */
export interface BackEndAPINewsUpdateOptionalParams extends msRest.RequestOptionsBase {
  body?: News;
}

/**
 * Optional Parameters.
 */
export interface BackEndAPIPendingNewsSetStatusOptionalParams extends msRest.RequestOptionsBase {
  body?: PendingNewsSetStatusFiltru;
}

/**
 * Optional Parameters.
 */
export interface BackEndAPIPendingNewsGetOptionalParams extends msRest.RequestOptionsBase {
  body?: number;
}

/**
 * Optional Parameters.
 */
export interface BackEndAPIVerifyRecaptchaOptionalParams extends msRest.RequestOptionsBase {
  body?: RecaptchaRequest;
}

/**
 * Contains response data for the login operation.
 */
export type LoginResponse2 = LoginResponse & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: LoginResponse;
    };
};

/**
 * Contains response data for the validateToken operation.
 */
export type ValidateTokenResponse2 = ValidateTokenResponse & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ValidateTokenResponse;
    };
};

/**
 * Contains response data for the highwaysSelect operation.
 */
export type HighwaysSelectResponse = Array<Highway> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Highway[];
    };
};

/**
 * Contains response data for the highwaysDetailedSelect operation.
 */
export type HighwaysDetailedSelectResponse = Array<HighwayDetailed> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: HighwayDetailed[];
    };
};

/**
 * Contains response data for the highwaysSubdivisionsSelect operation.
 */
export type HighwaysSubdivisionsSelectResponse = Array<HighwaySubdivision> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: HighwaySubdivision[];
    };
};

/**
 * Contains response data for the highwaysSubdivisionsGet operation.
 */
export type HighwaysSubdivisionsGetResponse = HighwaySubdivision & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: HighwaySubdivision;
    };
};

/**
 * Contains response data for the highwaysDescriptionsSelect operation.
 */
export type HighwaysDescriptionsSelectResponse = Array<HighwayDescription> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: HighwayDescription[];
    };
};

/**
 * Contains response data for the staticResourcesSelect operation.
 */
export type StaticResourcesSelectResponse = Array<StaticResource> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: StaticResource[];
    };
};

/**
 * Contains response data for the mapSectionsSelectJson operation.
 */
export type MapSectionsSelectJsonResponse = Array<string> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string[];
    };
};

/**
 * Contains response data for the mapSectionsSelectGeo operation.
 */
export type MapSectionsSelectGeoResponse = Array<string> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string[];
    };
};

/**
 * Contains response data for the mapEVStationsSelect operation.
 */
export type MapEVStationsSelectResponse = Array<string> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string[];
    };
};

/**
 * Contains response data for the newsSelectTop3 operation.
 */
export type NewsSelectTop3Response = Array<News> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: News[];
    };
};

/**
 * Contains response data for the newsSelect operation.
 */
export type NewsSelectResponse = Array<News> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: News[];
    };
};

/**
 * Contains response data for the newsGet operation.
 */
export type NewsGetResponse = News & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: News;
    };
};

/**
 * Contains response data for the pendingNewsSelect operation.
 */
export type PendingNewsSelectResponse = Array<PendingNews> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PendingNews[];
    };
};

/**
 * Contains response data for the pendingNewsGet operation.
 */
export type PendingNewsGetResponse = PendingNews & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PendingNews;
    };
};
