/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "./models";
import * as Mappers from "./models/mappers";
import * as Parameters from "./models/parameters";
import { BackEndAPIContext } from "./backEndAPIContext";

class BackEndAPI extends BackEndAPIContext {
  /**
   * Initializes a new instance of the BackEndAPI class.
   * @param credentials Subscription credentials which uniquely identify client subscription.
   * @param [options] The parameter options
   */
  constructor(credentials: msRest.ServiceClientCredentials, options?: Models.BackEndAPIOptions) {
    super(credentials, options);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.LoginResponse2>
   */
  login(options?: Models.BackEndAPILoginOptionalParams): Promise<Models.LoginResponse2>;
  /**
   * @param callback The callback
   */
  login(callback: msRest.ServiceCallback<Models.LoginResponse>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  login(options: Models.BackEndAPILoginOptionalParams, callback: msRest.ServiceCallback<Models.LoginResponse>): void;
  login(options?: Models.BackEndAPILoginOptionalParams | msRest.ServiceCallback<Models.LoginResponse>, callback?: msRest.ServiceCallback<Models.LoginResponse>): Promise<Models.LoginResponse2> {
    return this.sendOperationRequest(
      {
        options
      },
      loginOperationSpec,
      callback) as Promise<Models.LoginResponse2>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.ValidateTokenResponse2>
   */
  validateToken(options?: Models.BackEndAPIValidateTokenOptionalParams): Promise<Models.ValidateTokenResponse2>;
  /**
   * @param callback The callback
   */
  validateToken(callback: msRest.ServiceCallback<Models.ValidateTokenResponse>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  validateToken(options: Models.BackEndAPIValidateTokenOptionalParams, callback: msRest.ServiceCallback<Models.ValidateTokenResponse>): void;
  validateToken(options?: Models.BackEndAPIValidateTokenOptionalParams | msRest.ServiceCallback<Models.ValidateTokenResponse>, callback?: msRest.ServiceCallback<Models.ValidateTokenResponse>): Promise<Models.ValidateTokenResponse2> {
    return this.sendOperationRequest(
      {
        options
      },
      validateTokenOperationSpec,
      callback) as Promise<Models.ValidateTokenResponse2>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  contactMessageInsert(options?: Models.BackEndAPIContactMessageInsertOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  contactMessageInsert(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  contactMessageInsert(options: Models.BackEndAPIContactMessageInsertOptionalParams, callback: msRest.ServiceCallback<void>): void;
  contactMessageInsert(options?: Models.BackEndAPIContactMessageInsertOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      contactMessageInsertOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.HighwaysSelectResponse>
   */
  highwaysSelect(options?: msRest.RequestOptionsBase): Promise<Models.HighwaysSelectResponse>;
  /**
   * @param callback The callback
   */
  highwaysSelect(callback: msRest.ServiceCallback<Models.Highway[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  highwaysSelect(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Highway[]>): void;
  highwaysSelect(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Highway[]>, callback?: msRest.ServiceCallback<Models.Highway[]>): Promise<Models.HighwaysSelectResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      highwaysSelectOperationSpec,
      callback) as Promise<Models.HighwaysSelectResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.HighwaysDetailedSelectResponse>
   */
  highwaysDetailedSelect(options?: Models.BackEndAPIHighwaysDetailedSelectOptionalParams): Promise<Models.HighwaysDetailedSelectResponse>;
  /**
   * @param callback The callback
   */
  highwaysDetailedSelect(callback: msRest.ServiceCallback<Models.HighwayDetailed[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  highwaysDetailedSelect(options: Models.BackEndAPIHighwaysDetailedSelectOptionalParams, callback: msRest.ServiceCallback<Models.HighwayDetailed[]>): void;
  highwaysDetailedSelect(options?: Models.BackEndAPIHighwaysDetailedSelectOptionalParams | msRest.ServiceCallback<Models.HighwayDetailed[]>, callback?: msRest.ServiceCallback<Models.HighwayDetailed[]>): Promise<Models.HighwaysDetailedSelectResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      highwaysDetailedSelectOperationSpec,
      callback) as Promise<Models.HighwaysDetailedSelectResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.HighwaysSubdivisionsSelectResponse>
   */
  highwaysSubdivisionsSelect(options?: Models.BackEndAPIHighwaysSubdivisionsSelectOptionalParams): Promise<Models.HighwaysSubdivisionsSelectResponse>;
  /**
   * @param callback The callback
   */
  highwaysSubdivisionsSelect(callback: msRest.ServiceCallback<Models.HighwaySubdivision[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  highwaysSubdivisionsSelect(options: Models.BackEndAPIHighwaysSubdivisionsSelectOptionalParams, callback: msRest.ServiceCallback<Models.HighwaySubdivision[]>): void;
  highwaysSubdivisionsSelect(options?: Models.BackEndAPIHighwaysSubdivisionsSelectOptionalParams | msRest.ServiceCallback<Models.HighwaySubdivision[]>, callback?: msRest.ServiceCallback<Models.HighwaySubdivision[]>): Promise<Models.HighwaysSubdivisionsSelectResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      highwaysSubdivisionsSelectOperationSpec,
      callback) as Promise<Models.HighwaysSubdivisionsSelectResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.HighwaysSubdivisionsGetResponse>
   */
  highwaysSubdivisionsGet(options?: Models.BackEndAPIHighwaysSubdivisionsGetOptionalParams): Promise<Models.HighwaysSubdivisionsGetResponse>;
  /**
   * @param callback The callback
   */
  highwaysSubdivisionsGet(callback: msRest.ServiceCallback<Models.HighwaySubdivision>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  highwaysSubdivisionsGet(options: Models.BackEndAPIHighwaysSubdivisionsGetOptionalParams, callback: msRest.ServiceCallback<Models.HighwaySubdivision>): void;
  highwaysSubdivisionsGet(options?: Models.BackEndAPIHighwaysSubdivisionsGetOptionalParams | msRest.ServiceCallback<Models.HighwaySubdivision>, callback?: msRest.ServiceCallback<Models.HighwaySubdivision>): Promise<Models.HighwaysSubdivisionsGetResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      highwaysSubdivisionsGetOperationSpec,
      callback) as Promise<Models.HighwaysSubdivisionsGetResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.HighwaysDescriptionsSelectResponse>
   */
  highwaysDescriptionsSelect(options?: Models.BackEndAPIHighwaysDescriptionsSelectOptionalParams): Promise<Models.HighwaysDescriptionsSelectResponse>;
  /**
   * @param callback The callback
   */
  highwaysDescriptionsSelect(callback: msRest.ServiceCallback<Models.HighwayDescription[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  highwaysDescriptionsSelect(options: Models.BackEndAPIHighwaysDescriptionsSelectOptionalParams, callback: msRest.ServiceCallback<Models.HighwayDescription[]>): void;
  highwaysDescriptionsSelect(options?: Models.BackEndAPIHighwaysDescriptionsSelectOptionalParams | msRest.ServiceCallback<Models.HighwayDescription[]>, callback?: msRest.ServiceCallback<Models.HighwayDescription[]>): Promise<Models.HighwaysDescriptionsSelectResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      highwaysDescriptionsSelectOperationSpec,
      callback) as Promise<Models.HighwaysDescriptionsSelectResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.StaticResourcesSelectResponse>
   */
  staticResourcesSelect(options?: msRest.RequestOptionsBase): Promise<Models.StaticResourcesSelectResponse>;
  /**
   * @param callback The callback
   */
  staticResourcesSelect(callback: msRest.ServiceCallback<Models.StaticResource[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  staticResourcesSelect(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.StaticResource[]>): void;
  staticResourcesSelect(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.StaticResource[]>, callback?: msRest.ServiceCallback<Models.StaticResource[]>): Promise<Models.StaticResourcesSelectResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      staticResourcesSelectOperationSpec,
      callback) as Promise<Models.StaticResourcesSelectResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.MapSectionsSelectJsonResponse>
   */
  mapSectionsSelectJson(options?: Models.BackEndAPIMapSectionsSelectJsonOptionalParams): Promise<Models.MapSectionsSelectJsonResponse>;
  /**
   * @param callback The callback
   */
  mapSectionsSelectJson(callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  mapSectionsSelectJson(options: Models.BackEndAPIMapSectionsSelectJsonOptionalParams, callback: msRest.ServiceCallback<string[]>): void;
  mapSectionsSelectJson(options?: Models.BackEndAPIMapSectionsSelectJsonOptionalParams | msRest.ServiceCallback<string[]>, callback?: msRest.ServiceCallback<string[]>): Promise<Models.MapSectionsSelectJsonResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      mapSectionsSelectJsonOperationSpec,
      callback) as Promise<Models.MapSectionsSelectJsonResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.MapSectionsSelectGeoResponse>
   */
  mapSectionsSelectGeo(options?: Models.BackEndAPIMapSectionsSelectGeoOptionalParams): Promise<Models.MapSectionsSelectGeoResponse>;
  /**
   * @param callback The callback
   */
  mapSectionsSelectGeo(callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  mapSectionsSelectGeo(options: Models.BackEndAPIMapSectionsSelectGeoOptionalParams, callback: msRest.ServiceCallback<string[]>): void;
  mapSectionsSelectGeo(options?: Models.BackEndAPIMapSectionsSelectGeoOptionalParams | msRest.ServiceCallback<string[]>, callback?: msRest.ServiceCallback<string[]>): Promise<Models.MapSectionsSelectGeoResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      mapSectionsSelectGeoOperationSpec,
      callback) as Promise<Models.MapSectionsSelectGeoResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.MapEVStationsSelectResponse>
   */
  mapEVStationsSelect(options?: msRest.RequestOptionsBase): Promise<Models.MapEVStationsSelectResponse>;
  /**
   * @param callback The callback
   */
  mapEVStationsSelect(callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  mapEVStationsSelect(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string[]>): void;
  mapEVStationsSelect(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string[]>, callback?: msRest.ServiceCallback<string[]>): Promise<Models.MapEVStationsSelectResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      mapEVStationsSelectOperationSpec,
      callback) as Promise<Models.MapEVStationsSelectResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.NewsSelectTop3Response>
   */
  newsSelectTop3(options?: msRest.RequestOptionsBase): Promise<Models.NewsSelectTop3Response>;
  /**
   * @param callback The callback
   */
  newsSelectTop3(callback: msRest.ServiceCallback<Models.News[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  newsSelectTop3(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.News[]>): void;
  newsSelectTop3(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.News[]>, callback?: msRest.ServiceCallback<Models.News[]>): Promise<Models.NewsSelectTop3Response> {
    return this.sendOperationRequest(
      {
        options
      },
      newsSelectTop3OperationSpec,
      callback) as Promise<Models.NewsSelectTop3Response>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.NewsSelectResponse>
   */
  newsSelect(options?: Models.BackEndAPINewsSelectOptionalParams): Promise<Models.NewsSelectResponse>;
  /**
   * @param callback The callback
   */
  newsSelect(callback: msRest.ServiceCallback<Models.News[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  newsSelect(options: Models.BackEndAPINewsSelectOptionalParams, callback: msRest.ServiceCallback<Models.News[]>): void;
  newsSelect(options?: Models.BackEndAPINewsSelectOptionalParams | msRest.ServiceCallback<Models.News[]>, callback?: msRest.ServiceCallback<Models.News[]>): Promise<Models.NewsSelectResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      newsSelectOperationSpec,
      callback) as Promise<Models.NewsSelectResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.NewsGetResponse>
   */
  newsGet(options?: Models.BackEndAPINewsGetOptionalParams): Promise<Models.NewsGetResponse>;
  /**
   * @param callback The callback
   */
  newsGet(callback: msRest.ServiceCallback<Models.News>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  newsGet(options: Models.BackEndAPINewsGetOptionalParams, callback: msRest.ServiceCallback<Models.News>): void;
  newsGet(options?: Models.BackEndAPINewsGetOptionalParams | msRest.ServiceCallback<Models.News>, callback?: msRest.ServiceCallback<Models.News>): Promise<Models.NewsGetResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      newsGetOperationSpec,
      callback) as Promise<Models.NewsGetResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  newsInsert(options?: Models.BackEndAPINewsInsertOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  newsInsert(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  newsInsert(options: Models.BackEndAPINewsInsertOptionalParams, callback: msRest.ServiceCallback<void>): void;
  newsInsert(options?: Models.BackEndAPINewsInsertOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      newsInsertOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  newsUpdate(options?: Models.BackEndAPINewsUpdateOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  newsUpdate(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  newsUpdate(options: Models.BackEndAPINewsUpdateOptionalParams, callback: msRest.ServiceCallback<void>): void;
  newsUpdate(options?: Models.BackEndAPINewsUpdateOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      newsUpdateOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.PendingNewsSelectResponse>
   */
  pendingNewsSelect(options?: msRest.RequestOptionsBase): Promise<Models.PendingNewsSelectResponse>;
  /**
   * @param callback The callback
   */
  pendingNewsSelect(callback: msRest.ServiceCallback<Models.PendingNews[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  pendingNewsSelect(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.PendingNews[]>): void;
  pendingNewsSelect(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.PendingNews[]>, callback?: msRest.ServiceCallback<Models.PendingNews[]>): Promise<Models.PendingNewsSelectResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      pendingNewsSelectOperationSpec,
      callback) as Promise<Models.PendingNewsSelectResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  pendingNewsSetStatus(options?: Models.BackEndAPIPendingNewsSetStatusOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  pendingNewsSetStatus(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  pendingNewsSetStatus(options: Models.BackEndAPIPendingNewsSetStatusOptionalParams, callback: msRest.ServiceCallback<void>): void;
  pendingNewsSetStatus(options?: Models.BackEndAPIPendingNewsSetStatusOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      pendingNewsSetStatusOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.PendingNewsGetResponse>
   */
  pendingNewsGet(options?: Models.BackEndAPIPendingNewsGetOptionalParams): Promise<Models.PendingNewsGetResponse>;
  /**
   * @param callback The callback
   */
  pendingNewsGet(callback: msRest.ServiceCallback<Models.PendingNews>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  pendingNewsGet(options: Models.BackEndAPIPendingNewsGetOptionalParams, callback: msRest.ServiceCallback<Models.PendingNews>): void;
  pendingNewsGet(options?: Models.BackEndAPIPendingNewsGetOptionalParams | msRest.ServiceCallback<Models.PendingNews>, callback?: msRest.ServiceCallback<Models.PendingNews>): Promise<Models.PendingNewsGetResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      pendingNewsGetOperationSpec,
      callback) as Promise<Models.PendingNewsGetResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  verifyRecaptcha(options?: Models.BackEndAPIVerifyRecaptchaOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  verifyRecaptcha(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  verifyRecaptcha(options: Models.BackEndAPIVerifyRecaptchaOptionalParams, callback: msRest.ServiceCallback<void>): void;
  verifyRecaptcha(options?: Models.BackEndAPIVerifyRecaptchaOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      verifyRecaptchaOperationSpec,
      callback);
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const loginOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/auth/login",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.LoginRequest
  },
  responses: {
    200: {
      bodyMapper: Mappers.LoginResponse
    },
    default: {}
  },
  serializer
};

const validateTokenOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/auth/validate",
  queryParameters: [
    Parameters.token
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ValidateTokenResponse
    },
    default: {}
  },
  serializer
};

const contactMessageInsertOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/cont/ci",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.ContactMessage
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const highwaysSelectOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/high/hs",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Highway"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const highwaysDetailedSelectOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/high/hds",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: {
      serializedName: "body",
      type: {
        name: "String"
      }
    }
  },
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "HighwayDetailed"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const highwaysSubdivisionsSelectOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/high/hss",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: {
      serializedName: "body",
      type: {
        name: "Number"
      }
    }
  },
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "HighwaySubdivision"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const highwaysSubdivisionsGetOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/high/hsg",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: {
      serializedName: "body",
      type: {
        name: "Number"
      }
    }
  },
  responses: {
    200: {
      bodyMapper: Mappers.HighwaySubdivision
    },
    default: {}
  },
  serializer
};

const highwaysDescriptionsSelectOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/high/hdes",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: {
      serializedName: "body",
      type: {
        name: "Number"
      }
    }
  },
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "HighwayDescription"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const staticResourcesSelectOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/info/srs",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "StaticResource"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const mapSectionsSelectJsonOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/maps/hss",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: {
      serializedName: "body",
      type: {
        name: "String"
      }
    }
  },
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const mapSectionsSelectGeoOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/maps/hssg",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: {
      serializedName: "body",
      type: {
        name: "String"
      }
    }
  },
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const mapEVStationsSelectOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/maps/evss",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const newsSelectTop3OperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/news/ns3",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "News"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const newsSelectOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/news/ns",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.NewsFiltru
  },
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "News"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const newsGetOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/news/ng",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: {
      serializedName: "body",
      type: {
        name: "Number"
      }
    }
  },
  responses: {
    200: {
      bodyMapper: Mappers.News
    },
    default: {}
  },
  serializer
};

const newsInsertOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/news/ni",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.News
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const newsUpdateOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/news/nu",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.News
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const pendingNewsSelectOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/news/pns",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PendingNews"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const pendingNewsSetStatusOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/news/pnss",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.PendingNewsSetStatusFiltru
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const pendingNewsGetOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/news/png",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: {
      serializedName: "body",
      type: {
        name: "Number"
      }
    }
  },
  responses: {
    200: {
      bodyMapper: Mappers.PendingNews
    },
    default: {}
  },
  serializer
};

const verifyRecaptchaOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/recap/v",
  requestBody: {
    parameterPath: [
      "options",
      "body"
    ],
    mapper: Mappers.RecaptchaRequest
  },
  responses: {
    200: {},
    default: {}
  },
  serializer
};

export {
  BackEndAPI,
  BackEndAPIContext,
  Models as BackEndAPIModels,
  Mappers as BackEndAPIMappers
};
