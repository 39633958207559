import { Injectable } from "@angular/core";
import { BaseComponent } from "../base.component";
import {BackEndAPINewsInsertOptionalParams, BackEndAPINewsSelectOptionalParams, BackEndAPIPendingNewsSetStatusOptionalParams, BackEndAPIVerifyRecaptchaOptionalParams, ContactMessage, Highway, HighwayDescription, HighwayDetailed, HighwaySubdivision, News, NewsFiltru, PendingNews } from "src/api/lib/models";


@Injectable({
    providedIn: 'root'
})

export class InformationService extends BaseComponent {

    constructor() {
        super();
    }

    staticResources: { [id: string] : string; } = {};

    public async StaticResourcesSelect() {
        var result = JSON.parse((await this.postData(api => api.staticResourcesSelect({})))._response.bodyAsText);
        
        result.forEach(resource => {
            this.staticResources[resource.code] = resource.value;
        });
    }

    public async NewsSelectTop3(): Promise<News[]> {
        
        return JSON.parse((await this.getData(api => api.newsSelectTop3({})))._response.bodyAsText);
    }

    public async MapSectionsSelectJson(code: string): Promise<string[]> {
        return JSON.parse((await this.getData(api => api.mapSectionsSelectJson({ body: code})))._response.bodyAsText);
    }

    public async MapSectionsSelectGeoJson(code: string): Promise<string[]> {
        return JSON.parse((await this.getData(api => api.mapSectionsSelectGeo({ body: code})))._response.bodyAsText);
    }

    public async MapEVStationsSelect(): Promise<string[]> {
        return JSON.parse((await this.getData(api => api.mapEVStationsSelect()))._response.bodyAsText);
    }
 
    public async NewsSelect(filtru: BackEndAPINewsSelectOptionalParams): Promise<News[]> {
        return JSON.parse((await this.getData(api => api.newsSelect(filtru)))._response.bodyAsText);
    }

    public async PendingNewsSelect(): Promise<PendingNews[]> {
        return JSON.parse((await this.getData(api => api.pendingNewsSelect()))._response.bodyAsText);
    }

    public async HighwaysFirstPageSelect(): Promise<Highway[]> {
        return JSON.parse((await this.getData(api => api.highwaysSelect({})))._response.bodyAsText);
    }

    public async HighwaysSelect(filter: string): Promise<HighwayDetailed[]> {
        return JSON.parse((await this.getData(api => api.highwaysDetailedSelect({body: filter})))._response.bodyAsText);
    }

    public async HighwaysDescriptionsSelect(idHighway: number): Promise<HighwayDescription[]> {
        return JSON.parse((await this.getData(api => api.highwaysDescriptionsSelect({body: idHighway})))._response.bodyAsText);
    }

    public async HighwaysSubdivisionsSelect(idHighway: number): Promise<HighwaySubdivision[]> {
        return JSON.parse((await this.getData(api => api.highwaysSubdivisionsSelect({body: idHighway})))._response.bodyAsText);
    }

    public async HighwaysSubdivisionsGet(idHighwaySubdivision: number): Promise<HighwaySubdivision> {
        return JSON.parse((await this.getData(api => api.highwaysSubdivisionsGet({body: idHighwaySubdivision})))._response.bodyAsText);
    }

    public async ContactMessageInsert(contactMessage: ContactMessage, recaptchaResponse: BackEndAPIVerifyRecaptchaOptionalParams): Promise<void> {
        let capchaResponse =  JSON.parse((await this.getData(api => api.verifyRecaptcha(recaptchaResponse)))._response.bodyAsText);
        
        if (capchaResponse.message === "reCAPTCHA verified successfully.") {
            await this.postData(api => api.contactMessageInsert({body: contactMessage})).then(() => {
                this.snackBarOpen("Mulțumim pentru mesaj. Vom încerca să răspundem în cel mai scurt timp posibil", "INFO");
            });   
        }
        else {
            this.snackBarOpen("Validarea reCAPTCHA a eșuat! Vă rugăm să reîncercați.", "INFO");
        } 
    }

    public async NewsInsert(toInsert: BackEndAPINewsInsertOptionalParams) {
        console.log(toInsert.body.newsDate)
        await this.postData(api => api.newsInsert(toInsert)).then(() => {
            this.snackBarOpen("Succes", "INFO");
        }); 
    }

    public async PendingNewsChangeStatus(filtru: BackEndAPIPendingNewsSetStatusOptionalParams) {
        await this.postData(api => api.pendingNewsSetStatus(filtru)).then(() => {
            this.snackBarOpen("Succes", "INFO");
        }); 
    }

    public async PendingNewsGet(idPendingNews: number): Promise<PendingNews> {
        return JSON.parse((await this.postData(api => api.pendingNewsGet({body: idPendingNews})))._response.bodyAsText);
    }

    public getValue(key: string) {
        return this.staticResources[key];
    }
}
