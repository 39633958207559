import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, Type, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { createPopper } from '@popperjs/core';
import { ComponentWithOnDestroyObservable, OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Observable } from 'rxjs';
import { HighwayDescription, HighwaySubdivision } from 'src/api/lib/models';
import { AppInjectorService } from 'src/app/app.injector.service';
import { BaseComponent } from 'src/app/base.component';
import { InformationService } from 'src/app/services/information.service';
import { AutostradaDetDetaliiComponent } from '../autostrada-det-detalii/autostrada-det-detalii.component';

@Component({
  selector: 'app-autostrada-det',
  templateUrl: './autostrada-det.component.html'
})
export class AutostradaDetComponent extends BaseComponent implements OnInit, AfterViewInit,  OnDestroy, ComponentWithOnDestroyObservable, OnDestroyMixin
{
  currentHighwayId: number;
  popoverShow = false;
  popper = document.createElement("div");
  @ViewChildren('imgElement') imgElement: QueryList<ElementRef>;
  public highwayDescriptions: HighwayDescription[] = [];
  public highwayDescriptionsHeader: HighwayDescription[] = [];
  public highwaySubdivisions: HighwaySubdivision[] = [];
  public highwaySections: Set<string> = new Set<string>();
  public highwaySectionsDetailed: TempTotal[] = [];
  panelOpenState = false;

  constructor(private route: ActivatedRoute, public informationService: InformationService) {
    super();
    this.currentHighwayId = Number(this.route.snapshot.params.id);
    this.highwayDescriptions = [];
    this.highwaySubdivisions = [];
    this.highwayDescriptionsHeader = [];
    this.highwaySectionsDetailed = [];
  }
  ngAfterViewInit(): void {
    
  }

  observeOnDestroy(): Observable<void> {
    return;
  }

  async ngOnInit() {
    this.popper.innerHTML = this.tooltipHtml;
    await this.getHighwayDescriptions();
    await this.getHighwaySubdivisions();

    if (this.highwayDescriptions.length >= 2) {
      this.highwayDescriptionsHeader.push(this.highwayDescriptions[0]);
      this.highwayDescriptionsHeader.push(this.highwayDescriptions[1]);

      this.highwayDescriptions.splice(0, 1)
      this.highwayDescriptions.splice(0, 1)
    }

    this.highwaySubdivisions.forEach(item => {
      this.highwaySections.add(item.sectionName);
    });

    this.highwaySections.forEach(item => {

      let totalSectionLength: number = 0;
      let y: number[] = [];

      var x = this.highwaySubdivisions.filter(x => x.sectionName === item)
      x.forEach(val => {
        totalSectionLength += val.subdivisionLength;
        y.push(val.openPercentage)
      });
      const average = ((y.reduce( ( p, c ) => p + c, 0 )) / (y.length)).toFixed(1);
      totalSectionLength = Math.round( totalSectionLength * 1e1 ) / 1e1;

      let tempSectionDetailed: TempTotal = {
          sectionName: item,
          totalLungime: totalSectionLength,
          proc: average
      }
      this.highwaySectionsDetailed.push(tempSectionDetailed);
    })
  }

  public async getHighwayDescriptions() {
    this.highwayDescriptions = await this.informationService.HighwaysDescriptionsSelect(this.currentHighwayId);
  }

  public async getHighwaySubdivisions() {
    this.highwaySubdivisions = await this.informationService.HighwaysSubdivisionsSelect(this.currentHighwayId);
  }

  toggleTooltip(event: any){
    
    if(this.popoverShow){
      this.popoverShow = false;
      this.destroyPopper();
    } else {
      
      this.popoverShow = true;
      this.changeHtml(event);
      this.createPoppper(event);
    }
  }

  changeHtml(id: any) {
    
    if (this.highwaySubdivisions.length > 0) {
      const currentSection : HighwaySubdivision = this.highwaySubdivisions.find(item => item.idHighwaySubdivision === id);
      // const html : string = this.tooltipHtml.replace('~nume~', currentSection.subdivisionName)
      //                         .replace('~stare~', currentSection.description)
      //                         .replace('~constructor~', currentSection.subdivisionBuilder)
      //                         .replace('~valoare~', currentSection.contractValue)
      //                         .replace('~data~', this.formatDate(currentSection.contractSignDate))
      //                         .replace('~XX~', currentSection.totalExecutionMonths.toString() )
      //                         .replace('~YY~', currentSection.planningMonths.toString())
      //                         .replace('~ZZ~', currentSection.executionMonths.toString());

      let content: string = this.subtip1.replace('~nume~', currentSection.subdivisionName)
                                        .replace('~stare~', currentSection.description);
      if (currentSection.subdivisionBuilder !== '-') {
        content += this.subtip2.replace('~constructor~', currentSection.subdivisionBuilder)
      }
      if (currentSection.contractValue !== '-') {
        content += this.subtip3.replace('~valoare~', currentSection.contractValue)
      }
      if (this.formatDate(currentSection.contractSignDate).substring(0, 4) !== '1900') {
        content += this.subtip4.replace('~data~', this.formatDate(currentSection.contractSignDate))
      }
      if (currentSection.totalExecutionMonths !== -1) {
        content += this.subtip5.replace('~XX~', currentSection.totalExecutionMonths.toString() )
                                .replace('~YY~', currentSection.planningMonths.toString())
                                .replace('~ZZ~', currentSection.executionMonths.toString());
      }

      const html : string = this.tooltipHtml.replace('~content~', content)
      this.popper = document.createElement("div");
      
      this.popper.innerHTML = html;
    }
  }

  public formatDate(d : Date) : string {
    return d.toString().substring(0, 7);
}

  destroyPopper(){
    this.popper.parentNode.removeChild(this.popper);
  }
  createPoppper(id: any){
    let index: number = -1;
    for(let i = 0; i < this.imgElement.toArray().length; i++) {
      const xyz = this.imgElement.toArray()[i].nativeElement.id;
      if (Number(xyz) === Number(id)) {
        index = i;
        break;
      }
    }
    createPopper(this.imgElement.toArray()[index].nativeElement, this.popper, {
      placement: "top"
    });
    this.imgElement.toArray()[index].nativeElement.parentNode.insertBefore(this.popper, this.imgElement.toArray()[index].nativeElement.nextSibling);

  }

  public getSubdivisions(temp: HighwaySubdivision[], section: string): any[] {
    return temp.filter(x => x.sectionName === section)
  }

  private tooltipHtml: string = `<div class="bg-blueGray-200 border-0 mt-3 block z-50 font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg">
  <div  style="padding-bottom: 0.75rem">
    ~content~
  </div>
</div>`;

 private subtip1: string = `
    <div class="bg-blueGray-200 text-blueGray-700 border-blueGray-200 opacity-75 font-semibold p-3 mb-0 border-b border-solid  uppercase rounded-t-lg font-bold">
      ~nume~
    </div>
    <div class="text-blueGray-700 px-3">
      Stare lot: ~stare~
    </div>
    `;

    private subtip2: string = `
    <div class="text-blueGray-700 px-3">
      Constructor: ~constructor~
    </div>
    `;

    private subtip3: string = `
    <div class="text-blueGray-700 px-3">
      Valoare contract: ~valoare~
    </div>
    `;

    private subtip4: string = `
    <div id="data-id" class="text-blueGray-700 px-3">
      Contract semnat la ~data~
    </div>`;

    private subtip5: string = `
    <div class="text-blueGray-700 px-3">
      Termen de execuție: ~XX~ luni (~YY~ pentru proiectare și ~ZZ~ pentru execuție)
    </div>`;

    private GetSelectedInfoImageId(event: any): number {

      let index: number = -1;
      let xyz: number = -1;
      for(let i = 0; i < this.imgElement.toArray().length; i++) {
        xyz = this.imgElement.toArray()[i].nativeElement.id;
        if (Number(xyz) === Number(event)) {
          index = i;
          break;
        }
      }
      return Number(xyz);
    }

    public OpenDialogSafe(event: any) {
      AutostradaDetComponent.OpenDialog(AutostradaDetDetaliiComponent, this, { idSubwaySubdivision:  this.GetSelectedInfoImageId(event)}, async (result) => {
        if (result) {
          //NU asteptam rezultate
        }
      });
    }

    private static OpenDialog<T extends ComponentWithOnDestroyObservable>(dialogType: Type<any>, component: T, data: any, afterClosed?: (result) => void) {
      const dialog = AppInjectorService.getInjector().get(MatDialog);
  
      const dialogRef = dialog.open(dialogType, {
        width: '700px',
        disableClose: true,
        data
      });

      dialogRef.backdropClick().subscribe(() => {
        dialogRef.close();
      });
  
      try {
        if (afterClosed !== undefined) {
          dialogRef.afterClosed()
            .pipe(untilComponentDestroyed(component))
            .subscribe({
              next: async (result) => {
                await afterClosed.apply(component, [result]);
              }
            });
        }
      } catch(e) {
        
      }
    }
}

export class TempTotal {
  public totalLungime: number;
  public proc: string;
  public sectionName: string
}
