import { Component, OnDestroy, OnInit } from '@angular/core';
import { BackEndAPINewsInsertOptionalParams, BackEndAPIPendingNewsSetStatusOptionalParams, PendingNews } from 'src/api/lib/models';
import { BaseComponent } from 'src/app/base.component';
import { InformationService } from 'src/app/services/information.service';

@Component({
  selector: 'app-pending-helper',
  templateUrl: './pending-helper.component.html'
})
export class PendingHelperComponent extends BaseComponent implements OnInit, OnDestroy {

  public pendingNewsList: PendingNews[] = [];

  constructor(public informationService: InformationService) {
    super();
    this.pendingNewsList = [];
  }

  async ngOnInit() {
    this.pendingNewsList = await this.informationService.PendingNewsSelect();
  }

  public async ChangePendingStatusOk(event: any) {
      const filtru: BackEndAPIPendingNewsSetStatusOptionalParams = {
        body: {
          pendingStatus: true,
          idPendingNews: event
        }
      };
      await this.informationService.PendingNewsChangeStatus(filtru);

      let okPendingNews = await this.informationService.PendingNewsGet(event);
      console.log(okPendingNews)
      await this.InsertNews(okPendingNews);

      await this.RefreshPendingNewsList();
  }

  public async ChangePendingStatusNOk(event: any) {
    const filtru: BackEndAPIPendingNewsSetStatusOptionalParams = {
      body: {
        pendingStatus: false,
        idPendingNews: event
      }
    };
    await this.informationService.PendingNewsChangeStatus(filtru);
    await this.RefreshPendingNewsList();
  }

  private async RefreshPendingNewsList() {
    this.pendingNewsList = [];
    this.pendingNewsList = await this.informationService.PendingNewsSelect();
  }

  private async InsertNews(pending: PendingNews) {
    const filtru: BackEndAPINewsInsertOptionalParams = {
            body: {
              title: pending.title,
              description: pending.description,
              newsDate: pending.newsDate,
              source: pending.source,
              newsLink: pending.newsLink
            }
          };
      console.log(filtru.body.newsDate)
      await this.informationService.NewsInsert(filtru);
  }

}
