import { Component, OnDestroy, OnInit } from '@angular/core';
import { HighwayDetailed } from 'src/api/lib/models';
import { BaseComponent } from 'src/app/base.component';
import { InformationService } from 'src/app/services/information.service';

@Component({
  selector: 'app-infrastructura',
  templateUrl: './infrastructura.component.html'
})
export class InfrastructuraComponent extends BaseComponent implements OnInit, OnDestroy {

  public highwayList : HighwayDetailed[] = [];
  public numberOfSections : number = 6;
  public highwayGroups : any;
  public filterValue: string = '';
  public hGroup0: any;
  public hGroup1: any;
  public hGroup2: any;
  public hGroup3: any;
  public hGroup4: any;
  public hGroup5: any;

  constructor(public informationService: InformationService) {
    super();
    this.highwayList = [];
    }

  async ngOnInit() {
    await this.refreshData();
  }

  private async refreshData() {
    await this.informationService.StaticResourcesSelect();
    await this.getHighways();
    this.highwayGroups = this.groupIntoSublists(this.highwayList)
    this.hGroup0 = this.getHighwaySublist(0);
    this.hGroup1 = this.getHighwaySublist(1);
    this.hGroup2 = this.getHighwaySublist(2);
    this.hGroup3 = this.getHighwaySublist(3);
    this.hGroup4 = this.getHighwaySublist(4);
    this.hGroup5 = this.getHighwaySublist(5);
  }

  public async getHighways() {
    this.highwayList = await this.informationService.HighwaysSelect(this.filterValue);
  }

  public groupIntoSublists<T>(list: T[], chunkSize: number = 3): T[][] {
    const grouped: T[][] = [];
    
    for (let i = 0; i < list.length; i += chunkSize) {
      grouped.push(list.slice(i, i + chunkSize));
    }
    return grouped;
  }

  public getHighwaySublist<T>(index: number): T[] {
    try {
      return this.highwayGroups[index];
    } catch { }
  }

  async change(event){
    this.filterValue = event.target.value;
    await this.refreshData();
  }

  public getStaticResource(key: string) : string{
    return this.informationService.getValue(key);
  }


}
