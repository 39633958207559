import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { BackEndAPINewsSelectOptionalParams, News } from 'src/api/lib/models';
import { BaseComponent } from 'src/app/base.component';
import { InformationService } from 'src/app/services/information.service';
import { BehaviorSubject, Observable } from "rxjs";
import { ComponentWithOnDestroyObservable, OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-stiri',
  templateUrl: './stiri.component.html'
})
export class StiriComponent extends BaseComponent implements OnInit, AfterViewInit,  OnDestroy, ComponentWithOnDestroyObservable, OnDestroyMixin {

  length = 0;
  pageSize = 10;
  pageIndex = 0;
  maxPages = -1;
  pageSizeOptions: number[] = [1, 2, 10, 25, 50, 100];
  public newsList : News[] = []
  public newsListTop3 : News[] = []
  @ViewChild('paginator', { static: true }) paginator: MatPaginator;

  private _dataSource = new BehaviorSubject<MatTableDataSource<News>>(null);
  public get dataSource(): MatTableDataSource<News>{
    return this._dataSource.getValue();
  }
  public set dataSource(value){
    this._dataSource.next(value);
  }
  
  constructor(public informationService: InformationService) {
    super();
    this.newsList = [];
    this.newsListTop3 = [];
    this.dataSource = new MatTableDataSource();
   }

  observeOnDestroy(): Observable<void> {
    return;
  }

   async ngAfterViewInit() {
    // this.paginator.page
    //   .pipe(untilComponentDestroyed(this)).subscribe({
    //     next: () => {
    //       this.newsList = [];
    //       this.newsListTop3 = [];
    //       this.getNews();
    //     }
    //   });
    }

    ngOnDestroy() {
    }

  async ngOnInit() {
    await this.getNews();
    try {
    this._dataSource.pipe(untilComponentDestroyed(this)).subscribe(item => {
      if (item.data.length > 0)
        this.length = (item.data[0] as any).nrElemente
      });
    }
    catch(e) {
      
    }
    
    // this.paginator._intl.itemsPerPageLabel="Test String";
  }

  public async getNews() {
    
    const filtru: BackEndAPINewsSelectOptionalParams = {
      body: {
        rowCount: this.pageSize,
        rowOffset: this.pageIndex * this.pageSize
      }
    };

    this.newsList = await this.informationService.NewsSelect(filtru);
    this.length = this.newsList[0].nrElemente

    this.newsListTop3.push(this.newsList[0])
    this.newsListTop3.push(this.newsList[1])
    this.newsListTop3.push(this.newsList[2])

    let tempNewsList = this.newsList;
    tempNewsList = tempNewsList.reverse();
    tempNewsList.pop()
    tempNewsList.pop()
    tempNewsList.pop()
    tempNewsList = tempNewsList.reverse();

    this.newsList = tempNewsList;

    this.dataSource = new MatTableDataSource<News>(this.newsList);
  }

  public formatDate(d : Date) : string {
      return d.toString().substring(0, 10);
  }

  async next() {
    this.pageIndex++;

    this.newsList = [];
    this.newsListTop3 = [];
    await this.getNews();

    

  }

  async prev() {
    this.pageIndex--;

    if (this.pageIndex < 0)
      this.pageIndex = 0;

    this.newsList = [];
    this.newsListTop3 = [];
    await this.getNews();
  }



}


