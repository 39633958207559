import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { Console } from "node:console";
import { Highway, News } from "src/api/lib/models";
import { BaseComponent } from "src/app/base.component";
import { InformationService } from "src/app/services/information.service";

@Component({
  selector: "app-index",
  templateUrl: "./index.component.html",
})
export class IndexComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

  public newsList : News[] = []
  public highwayListOdd : Highway[] = []
  public highwayListEven : Highway[] = []
  public windowWidth: number;

  constructor(public informationService: InformationService) {
    super();
    this.newsList = [];
    this.highwayListOdd = [];
    this.highwayListEven= [];
  }
  ngAfterViewInit(): void {
    
  }

  async ngOnInit(): Promise<void> {
    this.informationService.StaticResourcesSelect();
    await this.getNews();
    await this.getHighways();
    this.windowWidth = window.outerWidth;
  }

  public getStaticResource(key: string) : string{
    return this.informationService.getValue(key);
  }

  public async getNews() {
    this.newsList = await this.informationService.NewsSelectTop3();
  }

  public async getHighways() {
    const highwayList = await this.informationService.HighwaysFirstPageSelect();

    for (let i = 0; i < highwayList.length; i++) {
      if (i % 2 == 0)
        this.highwayListEven.push(highwayList[i]);
      else 
        this.highwayListOdd.push(highwayList[i]);
    }
  }

  public formatDate(d : Date) : string {
      return d.toString().substring(0, 10);
  }
}
