import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/base.component';
import { InformationService } from 'src/app/services/information.service';
import * as L from "leaflet";
import "leaflet.markercluster";

@Component({
  selector: 'app-ev-map',
  templateUrl: './ev-map.component.html',
  styleUrls: ['./ev-map.component.css']
})
export class EvMapComponent   extends BaseComponent
  implements OnInit, OnDestroy {

  constructor(public informationService: InformationService) {
      super();
    }

  async ngOnInit() {
    const map = L.map('map', {
          center: [45.9432, 24.9668], // Example coordinates
          zoom: 7.4, // Set initial zoom to a decimal value
          zoomSnap: 1, // Allows finer zoom levels (default is 1)
          zoomDelta: 1 // Allows zooming in smaller steps
      });

      // Set up the tile layer (using OpenStreetMap tiles)
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution: "&copy; OpenStreetMap",
      }).addTo(map);
  
      map.attributionControl.setPrefix(
        '<a href="https://leafletjs.com/">Leaflet</a>'
      );

      

      const attributionStyle = document.createElement("style");
      attributionStyle.innerHTML = `
      .leaflet-control-attribution {
          background: white;
          border-radius: 5px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
          font-size: 5px;
          line-height: 24px;
          opacity: 0.7;
      }
      .leaflet-control-attribution img {
      display: none !important;
      }
      
      `;
      document.head.appendChild(attributionStyle);

      
      if (window.self === window.top) {
      const legend = L.control({ position: "bottomleft" });
      
      legend.onAdd = function (map) {
        const div = L.DomUtil.create("div", "logoLegend");
  
        div.innerHTML = `
  
      <a href="/">
        <div style="background: white;
              padding: 15px;
              margin: 0px;
              border-radius: 50px;
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
              font-size: 8px;
              text-align: center;
              opacity: 0.7;
              display: flex;
              flex-direction: column;
              align-items: center;
              transition: opacity 0.3s ease-in-out;">
          <img src="assets/img/icon-nobg.png" width="30" height="30" alt="Legend Icon">
          <p style="margin-top: 5px;">&copy; via-romania.ro</p>
        </div>
      </a>
      `;
  
  
        return div;
      };
      
  
      legend.addTo(map);
    }
      

      const customIcon = L.icon({
        iconUrl: "assets/img/marker.png", // Replace with your own icon
        iconSize: [25,25], // Width, height in pixels
        iconAnchor: [20, 40], // Positioning relative to the icon size
        popupAnchor: [0, -40] // Position of popup
      });

      const markers = L.markerClusterGroup({
        iconCreateFunction: function (cluster) {
          // Get first marker in the cluster
          const childMarkers = cluster.getAllChildMarkers();
          const firstMarker = childMarkers[0];
      
          // Use the first marker's icon for the cluster
          return L.divIcon({
            html: `<img src="assets/img/marker-red.png" style="width:40px; height:40px;">`,
            className: "custom-cluster-icon",
            iconSize: [40, 40],
          });
        }
      });

      let chargingStations = await this.fetchChargingStations();

      chargingStations.forEach(value => {
           var marker = L.marker([value.lat, value.lon], {
              icon: customIcon
          }).addTo(markers).bindPopup(this.getSectionInfoHtml(value.tags));
      })

      map.addLayer(markers)
    

  }
  

  async fetchChargingStations(): Promise<any[]> {
    
    try {
      const response = await this.informationService.MapEVStationsSelect();
      let responseString = JSON.parse(response[0]);
  
      if (!responseString || responseString.elements.length === 0) {
        console.error("No data received from Overpass API.");
        return;
      }
  
      const wayStations = responseString.elements
        .filter((el) => el.lat && el.lon)
        .map((el) => ({
          id: el.id,
          lat: el.lat,
          lon: el.lon,
          tags: el.tags || {},
        }));
  
      return wayStations
    } catch (error) {
      console.error("Error fetching data from Overpass API:", error);
    }
  }


  private getSectionInfoHtml(jsonData: any) : string {

    let html : string = `<div style=" width: 250px;
                                      background-color: whitesmoke;
                                      border-radius: 8px;
                                      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
                                      padding: 16px;
                                      font-family: Arial, sans-serif;
                                      color: rgb(94, 163, 216);
                                      position: relative;">
  <div class="info-body">`

  if (jsonData.operator !== undefined && jsonData.operator !== '') {
    html += '<p style="margin: 0;font-size: 13px;"><strong>Operator</strong></p>'
    html += '<p style="margin: 0;font-size: 12px; padding-bottom:.5rem">' + jsonData.operator +'</p>'
  }

  if (jsonData.description !== undefined && jsonData.description !== '') {
    html += '<p style="margin: 0;font-size: 13px;"><strong>Descriere</strong></p>'
    html += '<p style="margin: 0;font-size: 12px; padding-bottom:.5rem">' + jsonData.description +'</p>'
  }

  if (jsonData.brand !== undefined && jsonData.brand !== '') {
    html += '<p style="margin: 0;font-size: 13px;"><strong>Brand</strong></p>'
    html += '<p style="margin: 0;font-size: 12px; padding-bottom:.5rem">' + jsonData.brand +'</p>'
  }

  if (jsonData.name !== undefined && jsonData.name !== '') {
    html += '<p style="margin: 0;font-size: 13px;"><strong>Nume</strong></p>'
    html += '<p style="margin: 0;font-size: 12px; padding-bottom:.5rem">' + jsonData.name +'</p>'
  }

  if (jsonData.information !== undefined && jsonData.information !== '') {
    html += '<p style="margin: 0;font-size: 13px;"><strong>Informații</strong></p>'
    html += '<p style="margin: 0;font-size: 12px; padding-bottom:.5rem">' + jsonData.information +'</p>'
  }

  if (jsonData.capacity !== undefined && jsonData.capacity !== '') {
    html += '<p style="margin: 0;font-size: 13px;"><strong>Capacitate</strong></p>'
    html += '<p style="margin: 0;font-size: 12px; padding-bottom:.5rem">' + jsonData.capacity +'</p>'
  }

  if (jsonData.charge !== undefined && jsonData.charge !== '') {
    html += '<p style="margin: 0;font-size: 13px;"><strong>Cost</strong></p>'
    html += '<p style="margin: 0;font-size: 12px; padding-bottom:.5rem">' + jsonData.charge +'</p>'
  }

  if (jsonData.fee !== undefined && jsonData.fee !== '') {
    html += '<p style="margin: 0;font-size: 13px;"><strong>Cu plata</strong></p>'
    html += '<p style="margin: 0;font-size: 12px; padding-bottom:.5rem">' + (jsonData.fee === "yes" ? "Da" : "Nu") +'</p>'
  }
  
    return html;
  }

}

interface OverpassElement {
  type: string;
  id: number;
  lat?: number;
  lon?: number;
  tags?: Record<string, string>;
}

interface OverpassResponse {
  elements: OverpassElement[];
}
