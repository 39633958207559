import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-politica-conf',
  templateUrl: './politica-conf.component.html',
  styleUrls: ['./politica-conf.component.css']
})
export class PoliticaConfComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  public getdate(): any {
    const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm'; 
    let _now: moment.Moment;
    _now = moment(new Date(), DATE_TIME_FORMAT);
    return moment(_now).format('ll');
  }

}
