import { Component, OnDestroy, OnInit } from "@angular/core";
import { BackEndAPI } from "src/api/lib/backEndAPI";
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AppInjectorService } from "./app.injector.service";
import * as msRest from '@azure/ms-rest-js';
import { ApiResponse } from "src/api/lib/models/base.models";

@Component({
    template: ''
  })
  export class BaseComponent implements OnInit, OnDestroy {

    protected apiClient: BackEndAPI;
    public esteOcupat = false;
    private apiUrl: string;
    public snackBar: MatSnackBar;
    private router: Router;
    private snackBarDuration = 2000;
    private snackBarWarningDuration = 10000;
    
    constructor() {
        this.InjectDIs();
        this.InitBackEndAPI();
    }

    // Extrage dependintele
  private InjectDIs() {
    const injector = AppInjectorService.getInjector();
    if (injector) {
      this.apiUrl = injector.get('API_URL');
      this.router = injector.get(Router);
      this.snackBar = injector.get(MatSnackBar);

    }
  }

  // Initializeaza clientul pentru API
  public InitBackEndAPI() {
    const credentials = new msRest.TokenCredentials("NO_TOKEN_NEEDED_FOR_THE_MOMENT");
    const options = { baseUri: this.apiUrl, noRetryPolicy: true, requestPolicyFactories: [] };

    this.apiClient = new BackEndAPI(credentials, options);
  }

     ngOnInit() {
        
    }
  
    ngOnDestroy() { }

    async getData<T>(actiune: (x: BackEndAPI) => Promise<ApiResponse<T>>): Promise<T> {
        try {
          return await actiune(this.apiClient) as ApiResponse<T>;
        }
        catch (ex) {
            this.snackBarOpen(ex, "Va rugam sa reincercati", 6000);
            throw ex;
          
        }
        finally{
        }
      }

      async getActiune(actiune: (x: BackEndAPI) => Promise<ApiResponse<any>>): Promise<any> {
        try {
          return await actiune(this.apiClient) as ApiResponse<any>;
        }
        catch (ex) {
            this.snackBarOpen(ex, "Va rugam sa reincercati", 6000);
            throw ex;
        }
        finally{
        }
        
      }

      async postData(actiune: (x: BackEndAPI) => Promise<ApiResponse<any>>): Promise<any> {
        try {
          return await actiune(this.apiClient)  as ApiResponse<any>;
        }
        catch (ex) {
            this.snackBarOpen(ex, "Va rugam sa reincercati", 6000);
            throw ex;
        }
        finally{
        }
      }

      async ruleaza(actiune: () => Promise<void>): Promise<void> {
        try {
          this.esteOcupat = true;
          await actiune();
        } catch (ex) {
            this.snackBarOpen(ex, "Va rugam sa reincercati", 6000);
            throw ex;
        } finally {
          this.esteOcupat = false;
        }
      }

      snackBarOpen(message: any, action?: string, duration?: number) {
        if (message && message.message && message.message.length > 300)
          message = message.message.substring(0, 300);
    
        if (message > 300)
          message = message.substring(0, 300);
    
        this.snackBar.open(message,
          action !== undefined ? action : "ERROR",
          {
            duration: duration !== undefined ? duration : this.snackBarDuration
          });
      }

      snackBarOpenWarning(message: any, action?: string, duration?: number) {

        if (message && message.message && message.length > 300)
          message = message.message.substring(0, 300);
    
        if (message > 300)
          message = message.substring(0, 300);
    
        this.snackBar.open(message,
            action !== undefined ? action : "ERROR",
            {
            duration: duration !== undefined ? duration : this.snackBarWarningDuration,
            panelClass: ['theme-warn-background', 'theme-warn-foreground', 'snackbar-warning']
          });
      }

      protected async performOperationWithLoading(actionDo: () => Promise<void>, actionOnException: () => Promise<void> = null, actionFinally: () => Promise<void> = null){
        try{
          if (actionDo != null)
            await actionDo();
        }catch(exc){
          if (actionOnException != null)
            await actionOnException();
    
            this.snackBarOpen("Eroare", "Inchide",3000);
        }finally{
          if (actionFinally != null)
            await actionFinally();
        }
  }
  }

