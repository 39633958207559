import { Component, OnDestroy, OnInit } from "@angular/core";
import * as L from "leaflet";
import * as path from "path";
import { BaseComponent } from "src/app/base.component";
import { InformationService } from "src/app/services/information.service";

@Component({
  selector: "app-interactive-map",
  templateUrl: "./interactive-map.component.html",
  styleUrls: ["./interactive-map.component.css"],
})
export class InteractiveMapComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  constructor(public informationService: InformationService) {
    super();
  }

  async ngOnInit() {

    const map = L.map('map', {
      center: [45.9432, 24.9668], // Example coordinates
      zoom: 7.4, // Set initial zoom to a decimal value
      zoomSnap: 0.5, // Allows finer zoom levels (default is 1)
      zoomDelta: 0.5 // Allows zooming in smaller steps
  });
    const groupedPolygons: { [key: string]: L.LayerGroup } = {};
    const groupedImages: { [key: string]: L.LayerGroup } = {};
    const groupedDots: { [key: string]: L.LayerGroup } = {};

    // Set up the tile layer (using OpenStreetMap tiles)
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution: "&copy; OpenStreetMap",
    }).addTo(map);

    map.attributionControl.setPrefix(
      '<a href="https://leafletjs.com/">Leaflet</a>'
    );

    const attributionStyle = document.createElement("style");
    attributionStyle.innerHTML = `
    .leaflet-control-attribution {
        background: white;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        font-size: 5px;
        line-height: 24px;
        opacity: 0.7;
    }
    .leaflet-control-attribution img {
    display: none !important;
}

`;
    document.head.appendChild(attributionStyle);

    if (window.self === window.top) {
    const legend = L.control({ position: "bottomleft" });

    legend.onAdd = function (map) {
      const div = L.DomUtil.create("div", "logoLegend");

      div.innerHTML = `

    <a href="/">
      <div style="background: white;
            padding: 15px;
            margin: 0px;
            border-radius: 50px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            font-size: 8px;
            text-align: center;
            opacity: 0.7;
            display: flex;
            flex-direction: column;
            align-items: center;
            transition: opacity 0.3s ease-in-out;">
        <img src="assets/img/icon-nobg.png" width="30" height="30" alt="Legend Icon">
        <p style="margin-top: 5px;">via-romania.ro</p>
      </div>
    </a>
    `;


      return div;
    };

    legend.addTo(map);
  }

    const topRightLegend = L.control({ position: "topright" });

    topRightLegend.onAdd = function () {
      const div = L.DomUtil.create("div", "legend");
      div.innerHTML = `
      <div style="background: white;
        padding: 15px;
        margin: 10px;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        font-size: 14px;
        line-height: 24px;
        opacity: 0.8;" id="legendContent">
        <button style="" id="toggleLegend">Vizualizare secțiuni ▼</button><br>
        <div id="legendContent2">
          <input style="margin-bottom: 8px; background-color: #0b9c31" type="checkbox" id="checkFinalizat" checked> Finalizat<br>
          <input style="margin-bottom: 8px; background-color: #f97316" type="checkbox" id="checkExecutie" checked> În execuție<br>
          <input style="margin-bottom: 8px; background-color: #a60c0c" type="checkbox" id="checkProiectare" checked> În proiectare, fără AC<br>
          <input style="margin-bottom: 8px; background-color: #c469db" type="checkbox" id="checkLicitatie" checked> În licitare<br>
          <input style="margin-bottom: 8px; background-color: #3d3d3d" type="checkbox" id="checkPlanificat" checked> Planificat<br>
        </div>
      </div>
    `;
    
    setTimeout(() => {
      const toggleButton = document.getElementById("toggleLegend");
      const legendContent = document.getElementById("legendContent2");
      
      if (toggleButton && legendContent) {
          toggleButton.addEventListener("click", () => {
              if (legendContent.style.display === "none") {
                  legendContent.style.display = "block";
                  toggleButton.innerHTML = "Vizualizare secțiuni ▼";
              } else {
                  legendContent.style.display = "none";
                  toggleButton.innerHTML = "Vizualizare secțiuni ▶"; // Change icon to indicate collapsed state
              }
          });
      }
  }, 100);

      // Wait for the legend to be added to the DOM before attaching event listeners
      setTimeout(() => {
        document
          .getElementById("checkFinalizat")
          ?.addEventListener("change", toggleHighways);
          document
          .getElementById("checkExecutie")
          ?.addEventListener("change", toggleHighways);
          document
          .getElementById("checkProiectare")
          ?.addEventListener("change", toggleHighways);
          document
          .getElementById("checkLicitatie")
          ?.addEventListener("change", toggleHighways);
          document
          .getElementById("checkPlanificat")
          ?.addEventListener("change", toggleHighways);
      }, 100);

      return div;
    };

    // Add the legend to the map
    
    topRightLegend.addTo(map);

    function toggleHighways(event: Event) {
      const checked = (event.target as HTMLInputElement).checked;
      if (checked) {
        if ((event.target as HTMLInputElement).id === "checkFinalizat") {
          showGroup("Finalizat")
        }
        if ((event.target as HTMLInputElement).id === "checkExecutie") {
          showGroup("În execuție")
        }
        if ((event.target as HTMLInputElement).id === "checkProiectare") {
          showGroup("În proiectare, fără AC")
        }
        if ((event.target as HTMLInputElement).id === "checkLicitatie") {
          showGroup("În licitare")
        }
        if ((event.target as HTMLInputElement).id === "checkPlanificat") {
          showGroup("Planificat")
        }
      } else {
        if ((event.target as HTMLInputElement).id === "checkFinalizat") {
          hideGroup("Finalizat")
        }
        if ((event.target as HTMLInputElement).id === "checkExecutie") {
          hideGroup("În execuție")
        }
        if ((event.target as HTMLInputElement).id === "checkProiectare") {
          hideGroup("În proiectare, fără AC")
        }
        if ((event.target as HTMLInputElement).id === "checkLicitatie") {
          hideGroup("În licitare")
        }
        if ((event.target as HTMLInputElement).id === "checkPlanificat") {
          hideGroup("Planificat")
        }
      }
    }

    // Function to hide a specific group
function hideGroup(category: string) {
  if (groupedPolygons[category]) {
      map.removeLayer(groupedPolygons[category]);
  }
  map.removeLayer(groupedImages["image"])
  map.removeLayer(groupedDots["dots"])
}

// Function to show a specific group
function showGroup(category: string) {
  if (groupedPolygons[category]) {
      map.addLayer(groupedPolygons[category]);
  }
  let check1 = (document.getElementById('checkFinalizat') as HTMLInputElement).checked;
  let check2 = (document.getElementById('checkExecutie') as HTMLInputElement).checked;
  let check3 = (document.getElementById('checkProiectare') as HTMLInputElement).checked;
  let check4 = (document.getElementById('checkLicitatie') as HTMLInputElement).checked;
  let check5 = (document.getElementById('checkPlanificat') as HTMLInputElement).checked;

  if (check1 && check2 && check3 && check4 && check5) {
    map.addLayer(groupedImages["image"])
    map.addLayer(groupedDots["dots"])
  }
}

    function getSectionInfoHtml(jsonData: any) : string {
      let html : string = `<div style=" width: 250px;
                                        background-color: whitesmoke;
                                        border-radius: 8px;
                                        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
                                        padding: 16px;
                                        font-family: Arial, sans-serif;
                                        color: rgb(94, 163, 216);
                                        position: relative;">
                            <div class="info-header">
                              <p style=" margin: 4px 0; font-size: 14px; color: rgb(94, 163, 216);"><strong>Secțiune: </strong>` + jsonData.CodAutostrada + ': ' + jsonData.NumeTronson + `</p>
                            <hr style="border: none; border-top: 1px solid #ddd; margin: 10px 0;" />
    <div class="info-body">
      <p style="margin: 0; font-size: 13px;"><strong>Stare secțiune</strong></p>
      <p style="margin: 0; font-size: 12px; padding-bottom:.5rem">` + jsonData.Status  +`</p>`

    if (jsonData.Progres !== undefined && jsonData.Progres !== '') {
      html += '<p style="margin: 0;font-size: 13px;"><strong>Stadiu lucrări</strong></p>'
      html += '<p style="margin: 0;font-size: 12px; padding-bottom:.5rem">' + jsonData.Progres +'</p>'
    }

    if (jsonData.DataFinalizare !== undefined && jsonData.DataFinalizare !== '') {
      html += '<p style="margin: 0;font-size: 13px;"><strong>' + (jsonData.Status === 'Finalizat' ? 'Data finalizării' : 'Estimare finalizare construcție') + '</strong></p>'
      html += '<p style="margin: 0;font-size: 12px; padding-bottom:.5rem">' + jsonData.DataFinalizare +'</p>'
    }

    if (jsonData.Lungime !== undefined && jsonData.Lungime !== '') {
      html += '<p style="margin: 0;font-size: 13px;"><strong>Lungime lot</strong></p>'
      html += '<p style="margin: 0;font-size: 12px; padding-bottom:.5rem">' + jsonData.Lungime +'</p>'
    }

    if (jsonData.Constructor !== undefined && jsonData.Constructor !== '') {
      html += '<p style="margin: 0;font-size: 13px;"><strong>Constructor</strong></p>'
      html += '<p style="margin: 0;font-size: 12px; padding-bottom:.5rem">' + jsonData.Constructor +'</p>'
    }

    if (jsonData.Finantare !== undefined && jsonData.Finantare !== '') {
      html += '<p style="margin: 0;font-size: 13px;"><strong>Sursă de finanțare</strong></p>'
      html += '<p style="margin: 0;font-size: 12px; padding-bottom:.5rem">' + jsonData.Finantare +'</p>'
    }

    if (jsonData.Cost !== undefined && jsonData.Cost !== '') {
      html += '<p style="margin: 0;font-size: 13px;"><strong>Cost estimativ</strong></p>'
      html += '<p style="margin: 0;font-size: 12px; padding-bottom:.5rem">' + jsonData.Cost +'</p>'
    }

    if (jsonData.CodSeap !== undefined && jsonData.CodSeap !== '') {
      html += '<p style="margin: 0;font-size: 13px;"><strong>Cod SEAP licitație</strong></p>'
      html += '<p style="margin: 0;font-size: 12px; padding-bottom:.5rem">' + jsonData.CodSeap +'</p>'
    }

    if (jsonData.StadiuCurent !== undefined && jsonData.StadiuCurent !== '') {
      html += '<p style="margin: 0;font-size: 13px;"><strong>Stadiu curent</strong></p>'
      html += '<p style="margin: 0;font-size: 12px; padding-bottom:.5rem">' + jsonData.StadiuCurent +'</p>'
    }

    if (jsonData.DurataConstructie !== undefined && jsonData.DurataConstructie !== '') {
      html += '<p style="margin: 0;font-size: 13px;"><strong>Durată estimativă de construire</strong></p>'
      html += '<p style="margin: 0;font-size: 12px; padding-bottom:.5rem">' + jsonData.DurataConstructie +'</p>'
    }

    if (jsonData.FinalizareStudiuFez !== undefined && jsonData.FinalizareStudiuFez !== '') {
      html += '<p style="margin: 0;font-size: 13px;"><strong>Data finalizare studiu de fezabilitate</strong></p>'
      html += '<p style="margin: 0;font-size: 12px; padding-bottom:.5rem">' + jsonData.FinalizareStudiuFez +'</p>'
    }
    
      return html;
    }

    const jsonValues = await this.informationService.MapSectionsSelectJson("");
    const geojsonValues = await this.informationService.MapSectionsSelectGeoJson("");

    geojsonValues.forEach(async (value) => {
      let jsonValue = JSON.parse(value);
      const geoJsonData = await this.loadGeoJson(value);
      const coordinates = this.extractCoordinates(geoJsonData);

      coordinates.forEach((coordSet) => {
        const poly = L.polyline(coordSet, {
          color: this.getColor(jsonValue.Status),
          weight: 4,
        }).addTo(map);
        poly.on("click", function (event) {
          const popupContent = getSectionInfoHtml(jsonValue);
          L.popup()
            .setLatLng(event.latlng)
            .setContent(popupContent)
            .openOn(map);
        });
        if (!groupedPolygons[jsonValue.Status]) {
          groupedPolygons[jsonValue.Status] = L.layerGroup().addTo(map);
        }
        groupedPolygons[jsonValue.Status].addLayer(poly);
      });
    });

    jsonValues.forEach((value) => {
      let jsonValue = JSON.parse(value);
      let jsonData = JSON.parse(jsonValue.JsonData);
      const allCoordinates: number[][] = [];

      jsonData.elements.forEach((way) => {
        if (way.type === "way" && way.geometry) {
          var latlngs = way.geometry.map((node) => [node.lat, node.lon]);
          const poly = L.polyline(latlngs, {
            color: this.getColor(jsonValue.Status),
            weight: 4,
          }).addTo(map);
          poly.on("click", function (event) {
            const popupContent = getSectionInfoHtml(jsonValue);
            L.popup()
              .setLatLng(event.latlng)
              .setContent(popupContent)
              .openOn(map);
          });
          if (!groupedPolygons[jsonValue.Status]) {
            groupedPolygons[jsonValue.Status] = L.layerGroup().addTo(map);
          }
          groupedPolygons[jsonValue.Status].addLayer(poly);
          
          if (allCoordinates.length >= 2) {
            const startPoint = allCoordinates[0];
            const endPoint = allCoordinates[allCoordinates.length - 1]; 
          }
        }
      });
    });

    map.removeLayer(groupedPolygons["Planificat"])
    map.removeLayer(groupedPolygons["În licitare"])
    map.addLayer(groupedPolygons["Planificat"])
    map.addLayer(groupedPolygons["În licitare"])

    const markers: [number, number][] = [
      [47.1859312,26.0283367],
      [47.2139468,26.2524643],
      [47.1972852,26.6133397],
      [47.2632188,26.6900208],
      [47.2316292,27.0101832],
      [47.2089262,27.3742309],
      [47.2499968,27.7462532],
      [47.5166815,26.5795429],
      [47.6874035,26.3117957],
      [46.9731132,25.8138229],
      [46.7679375,25.5014482],
      [46.6777282,25.3839947],
      [46.5687054,25.0520554],
      [46.5167472,24.7867625],
      [46.9269473,23.3706769],
      [46.9925073,23.2415513],
      [47.0866324,23.1797234],
      [47.1777940,22.7039123],
      [47.2789836,22.2632562],
      [46.7933189,21.6939689],
      [46.5196005,21.5008579],
      [45.5618668,21.2491197],
      [45.2683856,21.2825802],
      [44.9439205,23.5111900],
      [44.7622173,23.4925192],
      [44.5700524,23.4850849],
      [44.4373536,23.6716210],
      [44.3619447,23.7786428],
      [45.1117340,24.6525843],
      [45.1649474,24.5602304],
      [45.3899608,24.3076233],
      [45.6460508,24.2577428],
      [45.7087341,24.3695480],
      [45.7709589,24.5963918],
      [45.7955033,24.8128043],
      [44.4805896,25.9004754],
      [44.4520879,26.2528344],
      [45.0825040,26.7447836],
      [45.1304778,26.8786550],
      [45.1602771,26.9131297],
      [45.7349332,27.2120566],
      [46.0299835,27.1838169],
      [46.3328469,27.0223779],
      [46.8899939,26.8801128],
      [47.0488788,26.8501883]
  ];


  markers.forEach((coord, index) => {
    var marker = L.circleMarker([coord[0], coord[1]], {
      radius: 1.6,        // Controls size
      color: "black",     // Border color
      fillColor: "#5ea3d8", // Inside color
      fillOpacity: 0.8   // Transparency
  }).addTo(map).bindPopup("Styled Circle Marker");
    
      if (!groupedDots["dots"]) {
        groupedDots["dots"] = L.layerGroup().addTo(map);
      }
      groupedDots["dots"].addLayer(marker);
    });

    this.addHighwayLogo("A1", [[44.511997, 25.124474],[45.348108, 24.025842],[46.019679, 22.311975]], map, groupedImages);
    this.addHighwayLogo("A2", [[44.535495, 27.475549]], map, groupedImages);
    this.addHighwayLogo("A4", [[44.000537, 28.283044]], map, groupedImages);
    this.addHighwayLogo("A5", [[44.067673, 26.102258]], map, groupedImages);
    this.addHighwayLogo("A6", [[43.984729, 24.635583],[45.197345, 22.542687]], map, groupedImages);
    this.addHighwayLogo("A9", [[45.475364, 21.471520]], map, groupedImages);
    this.addHighwayLogo("DEx12", [[44.621575, 24.300500]], map, groupedImages);
    this.addHighwayLogo("DEx16", [[46.611542, 21.812097]], map, groupedImages);

    this.addHighwayLogo("A0", [[44.245019, 26.365929]], map, groupedImages);
    this.addHighwayLogo("A13", [[45.663166, 24.659511],[45.916591, 26.310998]], map, groupedImages);
    this.addHighwayLogo("A10", [[46.160636, 23.899499]], map, groupedImages);
    this.addHighwayLogo("A3", [[46.995070, 22.603112], [46.562464, 24.157677],[45.407110, 25.802011],[44.736961, 26.446084]], map, groupedImages);
    this.addHighwayLogo("A7", [[45.405181, 26.766061],[46.570173, 27.209634], [47.454077, 26.819619]], map, groupedImages);
    this.addHighwayLogo("A8", [[46.828237, 25.910501],[47.090678, 27.228860]], map, groupedImages);

    
    this.addHighwayLogo("DEx8", [[44.725253, 28.464822]], map, groupedImages);
    this.addHighwayLogo("DEx14", [[47.470789, 23.669290],[47.478215, 25.196389]], map, groupedImages);
    this.addHighwayLogo("DEx6", [[45.435063, 27.734231]], map, groupedImages);
    this.addHighwayLogo("DEx4", [[46.875196, 24.020852]], map, groupedImages);

  }

  getColor(status: string): string {
    if (status === "Finalizat") return "#0b9c31";
    if (status === "În execuție") return "#f97316";
    if (status === "În proiectare, fără AC") return "#a60c0c";
    if (status === "Planificat") return "#3d3d3d";
    if (status === "În licitare") return "#c469db";
    return "blue";
  }

  extractCoordinates(geoJson: GeoJson): L.LatLngExpression[][] {
    let coordinates: L.LatLngExpression[][] = [];

      geoJson.features.forEach((feature) => {
        if (feature.geometry.type === "LineString") {
          const formattedCoords = (
            feature.geometry.coordinates as number[][]
          ).map((coord) => [coord[1], coord[0]]); // Swap [lon, lat] -> [lat, lon]
          coordinates.push(formattedCoords);
        } else if (feature.geometry.type === "Polygon") {
          feature.geometry.coordinates.forEach((ring) => {
            const formattedCoords = (ring as number[][]).map((coord) => [
              coord[1],
              coord[0],
            ]);
            coordinates.push(formattedCoords);
          });
        } else if (feature.geometry.type === "MultiPolygon") {
          feature.geometry.coordinates.forEach((polygon) => {
            polygon.forEach((ring) => {
              const formattedCoords = (ring as number[][]).map((coord) => [
                coord[1],
                coord[0],
              ]);
              coordinates.push(formattedCoords);
            });
          });
        }
      });

    return coordinates;
  }

  async loadGeoJson(file: string): Promise<GeoJson> {
    try {
      let jsonData = JSON.parse(file);
      const geoJsonData: GeoJson = JSON.parse(jsonData.JsonData);
      return geoJsonData;
    } catch (error) {
      console.error("Error loading GeoJSON:", error);
      throw error;
    }
  }



  addHighwayLogo(code: string, coords: [number, number][], map: any, group: any) {
    const iconHtmlA1 = `<div class="highway-logo-marker"><img src="assets/img/highways/${code}-RO.png" width="25px"></div>`;

    const logoIconA1 = L.divIcon({
        html: iconHtmlA1,
        className: `highway-logo-marker`, // Custom styling
        iconSize: [25, 25],  // Size of the div
        iconAnchor: [12.5, 12.5]  // Centered anchor point
    });

    coords.forEach(value => {
      const logoGroup = L.layerGroup().addTo(map);
      var image = L.marker([value[0], value[1]], { icon: logoIconA1 }).addTo(logoGroup);
      if (!group["image"]) {
        group["image"] = L.layerGroup().addTo(map);
      }
      group["image"].addLayer(image);
    } )
  }
}

interface GeoJsonFeature {
  type: string;
  geometry: {
    type: string;
    coordinates: number[][] | number[][][];
  };
  properties: any;
}

interface GeoJson {
  type: string;
  features: GeoJsonFeature[];
}
