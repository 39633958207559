import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormGroupDirective, NgForm} from '@angular/forms';
import { BackEndAPIVerifyRecaptchaOptionalParams, ContactMessage } from 'src/api/lib/models';
import { BaseComponent } from 'src/app/base.component';
import { InformationService } from 'src/app/services/information.service';

declare var grecaptcha: any; // Declare reCAPTCHA globally

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html'
})
export class ContactComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

  public contactForm: FormGroup = new FormGroup({
    fullName: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    message: new FormControl('', Validators.required),
    recaptchaResponse: new FormControl ('', Validators.required)
  });

  public isNameFirstAccess: boolean = true;
  public isEmailFirstAccess: boolean = true;
  public isMessageFirstAccess: boolean = true;
  //Test key
  recaptchaSiteKey = '6LcHbukqAAAAALIRhLwaS-SnX20c6noT1RgWAmCo';

  @ViewChild('formContactDirective', { static: false }) formContactDirective: FormGroupDirective;

  constructor(public informationService: InformationService) {
      super();
  }

  ngAfterViewInit(): void {
    
    
  }

  recaptchaCallback(response: string) {
    this.contactForm.patchValue({ recaptchaResponse: response });
  }

  ngOnInit(): void {
    this.informationService.StaticResourcesSelect();
    this.isNameFirstAccess = true;
    this.isEmailFirstAccess = true;
    this.isMessageFirstAccess = true;
    this.loadRecaptcha();
    (window as any).recaptchaCallback = (response: string) => this.recaptchaCallback(response);
  }

  public getStaticResource(key: string) : string{
    return this.informationService.getValue(key);
  }

  public async contactMessageInsert(): Promise<void> { 
    const contForm: ContactMessage = this.contactForm.value as ContactMessage;

    if (contForm.fullName === '' || contForm.email === '' || this.emailValidator(contForm.email) === false || contForm.message === '') {
      this.isNameFirstAccess = false;
      this.isEmailFirstAccess = false;
      this.isMessageFirstAccess = false;
      return;

    }

    if (this.contactForm.value.recaptchaResponse === null || this.contactForm.value.recaptchaResponse === "" || this.contactForm.value.recaptchaResponse === undefined) {
      this.snackBarOpen("Trebuie să completați verificarea reCAPTCHA pentru a trimite formularul.", "ERROR");
      return;
    }
    const filtru: BackEndAPIVerifyRecaptchaOptionalParams = {
          body: {
            recaptchaToken: this.contactForm.value.recaptchaResponse
          }
        };

    await this.informationService.ContactMessageInsert(contForm, filtru);
    this.contactForm.reset();
    this.formContactDirective.resetForm();
    this.isNameFirstAccess = true;
    this.isEmailFirstAccess = true;
    this.isMessageFirstAccess = true;
    grecaptcha.reset();
  }

  emailValidator(email:string): boolean {
    var EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!EMAIL_REGEXP.test(email)) {
        return false;
    }
    return true; 
  }

  onTextChangeName(event) {
    this.isNameFirstAccess = false;
  }

  onTextChangeEmail(event) {
    this.isEmailFirstAccess = false;
  }

  onTextChangeMessage(event) {
    this.isMessageFirstAccess = false;
  }

  // Load reCAPTCHA script dynamically
  loadRecaptcha() {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }
}
