/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is regenerated.
 */

import * as msRest from "@azure/ms-rest-js";


export const ContactMessage: msRest.CompositeMapper = {
  serializedName: "ContactMessage",
  type: {
    name: "Composite",
    className: "ContactMessage",
    modelProperties: {
      fullName: {
        serializedName: "fullName",
        type: {
          name: "String"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      message: {
        serializedName: "message",
        type: {
          name: "String"
        }
      },
      addDate: {
        serializedName: "addDate",
        type: {
          name: "DateTime"
        }
      },
      hasResponse: {
        serializedName: "hasResponse",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const Highway: msRest.CompositeMapper = {
  serializedName: "Highway",
  type: {
    name: "Composite",
    className: "Highway",
    modelProperties: {
      idHighway: {
        serializedName: "idHighway",
        type: {
          name: "Number"
        }
      },
      code: {
        serializedName: "code",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      popularName: {
        serializedName: "popularName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const HighwayDescription: msRest.CompositeMapper = {
  serializedName: "HighwayDescription",
  type: {
    name: "Composite",
    className: "HighwayDescription",
    modelProperties: {
      idHighway: {
        serializedName: "idHighway",
        type: {
          name: "Number"
        }
      },
      paragraph: {
        serializedName: "paragraph",
        type: {
          name: "String"
        }
      },
      paragraphOrder: {
        serializedName: "paragraphOrder",
        type: {
          name: "Number"
        }
      },
      isActive: {
        serializedName: "isActive",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const HighwayDetailed: msRest.CompositeMapper = {
  serializedName: "HighwayDetailed",
  type: {
    name: "Composite",
    className: "HighwayDetailed",
    modelProperties: {
      idHighway: {
        serializedName: "idHighway",
        type: {
          name: "Number"
        }
      },
      code: {
        serializedName: "code",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      popularName: {
        serializedName: "popularName",
        type: {
          name: "String"
        }
      },
      totalLength: {
        serializedName: "totalLength",
        type: {
          name: "Number"
        }
      },
      openLength: {
        serializedName: "openLength",
        type: {
          name: "Number"
        }
      },
      executionLength: {
        serializedName: "executionLength",
        type: {
          name: "Number"
        }
      },
      plannedLength: {
        serializedName: "plannedLength",
        type: {
          name: "Number"
        }
      },
      openPercentage: {
        serializedName: "openPercentage",
        type: {
          name: "Number"
        }
      },
      executionPercentage: {
        serializedName: "executionPercentage",
        type: {
          name: "Number"
        }
      },
      plannedPercentage: {
        serializedName: "plannedPercentage",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const HighwaySubdivision: msRest.CompositeMapper = {
  serializedName: "HighwaySubdivision",
  type: {
    name: "Composite",
    className: "HighwaySubdivision",
    modelProperties: {
      idHighwaySubdivision: {
        serializedName: "idHighwaySubdivision",
        type: {
          name: "Number"
        }
      },
      idHighway: {
        serializedName: "idHighway",
        type: {
          name: "Number"
        }
      },
      subdivisionName: {
        serializedName: "subdivisionName",
        type: {
          name: "String"
        }
      },
      subdivisionLength: {
        serializedName: "subdivisionLength",
        type: {
          name: "Number"
        }
      },
      sectionName: {
        serializedName: "sectionName",
        type: {
          name: "String"
        }
      },
      idHighwayStatus: {
        serializedName: "idHighwayStatus",
        type: {
          name: "Number"
        }
      },
      code: {
        serializedName: "code",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      color: {
        serializedName: "color",
        type: {
          name: "String"
        }
      },
      openEstimation: {
        serializedName: "openEstimation",
        type: {
          name: "String"
        }
      },
      openPercentage: {
        serializedName: "openPercentage",
        type: {
          name: "Number"
        }
      },
      subdivisionBuilder: {
        serializedName: "subdivisionBuilder",
        type: {
          name: "String"
        }
      },
      contractValue: {
        serializedName: "contractValue",
        type: {
          name: "String"
        }
      },
      contractSignDate: {
        serializedName: "contractSignDate",
        type: {
          name: "DateTime"
        }
      },
      totalExecutionMonths: {
        serializedName: "totalExecutionMonths",
        type: {
          name: "Number"
        }
      },
      planningMonths: {
        serializedName: "planningMonths",
        type: {
          name: "Number"
        }
      },
      executionMonths: {
        serializedName: "executionMonths",
        type: {
          name: "Number"
        }
      },
      highwayCode: {
        serializedName: "highwayCode",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      popularName: {
        serializedName: "popularName",
        type: {
          name: "String"
        }
      },
      totalLength: {
        serializedName: "totalLength",
        type: {
          name: "Number"
        }
      },
      executionPercentage: {
        serializedName: "executionPercentage",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const LoginRequest: msRest.CompositeMapper = {
  serializedName: "LoginRequest",
  type: {
    name: "Composite",
    className: "LoginRequest",
    modelProperties: {
      username: {
        serializedName: "username",
        type: {
          name: "String"
        }
      },
      password: {
        serializedName: "password",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Utilizator: msRest.CompositeMapper = {
  serializedName: "Utilizator",
  type: {
    name: "Composite",
    className: "Utilizator",
    modelProperties: {
      idUtilizator: {
        serializedName: "idUtilizator",
        type: {
          name: "Number"
        }
      },
      cod: {
        serializedName: "cod",
        type: {
          name: "Uuid"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      passwordHash: {
        serializedName: "passwordHash",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const LoginResponse: msRest.CompositeMapper = {
  serializedName: "LoginResponse",
  type: {
    name: "Composite",
    className: "LoginResponse",
    modelProperties: {
      utilizator: {
        serializedName: "utilizator",
        type: {
          name: "Composite",
          className: "Utilizator"
        }
      },
      jwt: {
        serializedName: "jwt",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const News: msRest.CompositeMapper = {
  serializedName: "News",
  type: {
    name: "Composite",
    className: "News",
    modelProperties: {
      nrElemente: {
        serializedName: "nrElemente",
        type: {
          name: "Number"
        }
      },
      idNews: {
        serializedName: "idNews",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      newsDate: {
        serializedName: "newsDate",
        type: {
          name: "DateTime"
        }
      },
      addDate: {
        serializedName: "addDate",
        type: {
          name: "DateTime"
        }
      },
      source: {
        serializedName: "source",
        type: {
          name: "String"
        }
      },
      newsLink: {
        serializedName: "newsLink",
        type: {
          name: "String"
        }
      },
      isActive: {
        serializedName: "isActive",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const NewsFiltru: msRest.CompositeMapper = {
  serializedName: "NewsFiltru",
  type: {
    name: "Composite",
    className: "NewsFiltru",
    modelProperties: {
      rowOffset: {
        serializedName: "rowOffset",
        type: {
          name: "Number"
        }
      },
      rowCount: {
        serializedName: "rowCount",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const PendingNews: msRest.CompositeMapper = {
  serializedName: "PendingNews",
  type: {
    name: "Composite",
    className: "PendingNews",
    modelProperties: {
      nrElemente: {
        serializedName: "nrElemente",
        type: {
          name: "Number"
        }
      },
      idPendingNews: {
        serializedName: "idPendingNews",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      newsDate: {
        serializedName: "newsDate",
        type: {
          name: "DateTime"
        }
      },
      addDate: {
        serializedName: "addDate",
        type: {
          name: "DateTime"
        }
      },
      source: {
        serializedName: "source",
        type: {
          name: "String"
        }
      },
      newsLink: {
        serializedName: "newsLink",
        type: {
          name: "String"
        }
      },
      pendingStatus: {
        serializedName: "pendingStatus",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const PendingNewsSetStatusFiltru: msRest.CompositeMapper = {
  serializedName: "PendingNewsSetStatusFiltru",
  type: {
    name: "Composite",
    className: "PendingNewsSetStatusFiltru",
    modelProperties: {
      pendingStatus: {
        serializedName: "pendingStatus",
        type: {
          name: "Boolean"
        }
      },
      idPendingNews: {
        serializedName: "idPendingNews",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const RecaptchaRequest: msRest.CompositeMapper = {
  serializedName: "RecaptchaRequest",
  type: {
    name: "Composite",
    className: "RecaptchaRequest",
    modelProperties: {
      recaptchaToken: {
        serializedName: "recaptchaToken",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const StaticResource: msRest.CompositeMapper = {
  serializedName: "StaticResource",
  type: {
    name: "Composite",
    className: "StaticResource",
    modelProperties: {
      idResource: {
        serializedName: "idResource",
        type: {
          name: "Number"
        }
      },
      code: {
        serializedName: "code",
        type: {
          name: "String"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      },
      isActive: {
        serializedName: "isActive",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const ValidateTokenResponse: msRest.CompositeMapper = {
  serializedName: "ValidateTokenResponse",
  type: {
    name: "Composite",
    className: "ValidateTokenResponse",
    modelProperties: {
      claims: {
        serializedName: "claims",
        type: {
          name: "String"
        }
      }
    }
  }
};
