import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { createPopper } from "@popperjs/core";
import { BaseComponent } from "src/app/base.component";
import { InformationService } from "src/app/services/information.service";

@Component({
  selector: "app-index-navbar",
  templateUrl: "./index-navbar.component.html",
})
export class IndexNavbarComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  navbarOpen = false;
  dropdownPopoverShow = false;
  popper = document.createElement("div");
  @ViewChild('btnDropdownRef',{ static: false }) btnDropdownRef: ElementRef;

    constructor(public informationService: InformationService) {
      super();
    }

    ngAfterViewInit(): void {
    
    }

  ngOnInit(): void {
    this.popper.innerHTML = `<div class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg mt-1" style="min-width: 18rem;" #popoverDropdownRef>
  <a href="/harta" class=" py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-blueGray-700 font-bold">
    Harta autostrăzilor
  </a>
  <div class="h-0 my-2 border border-solid border-t-0 border-blueGray-800 opacity-25"></div>
  <a href="/harta-ev" class=" py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-blueGray-700 font-bold">
    Harta stațiilor de încarcare 
  </a>
</div>`
  }

  setNavbarOpen() {
    this.navbarOpen = !this.navbarOpen;
  }

  public getStaticResource(key: string) : string{
    return this.informationService.getValue(key);
  }

  toggleDropdown(){
    if(this.dropdownPopoverShow){
      this.dropdownPopoverShow = false;
      this.destroyPopper();
    } else {
      this.dropdownPopoverShow = true;
      this.createPoppper();
    }
  }
  destroyPopper(){
    this.popper.parentNode.removeChild(this.popper);
  }
  createPoppper(){
    createPopper(this.btnDropdownRef.nativeElement, this.popper, {
      placement: "bottom-start"
    });
    this.btnDropdownRef.nativeElement.parentNode.insertBefore(this.popper, this.btnDropdownRef.nativeElement.nextSibling);

  }
}
