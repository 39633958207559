import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ComponentWithOnDestroyObservable, OnDestroyMixin } from '@w11k/ngx-componentdestroyed';
import { Observable } from 'rxjs';
import { HighwaySubdivision } from 'src/api/lib/models';
import { BaseComponent } from 'src/app/base.component';
import { InformationService } from 'src/app/services/information.service';

@Component({
  selector: 'app-autostrada-det-detalii',
  templateUrl: './autostrada-det-detalii.component.html'
})
export class AutostradaDetDetaliiComponent extends BaseComponent implements OnInit, ComponentWithOnDestroyObservable, OnDestroyMixin  {

  private idSubwaySubdivision: number;
  public subdivisionData: HighwaySubdivision;

  constructor(private dialogRef: MatDialogRef<AutostradaDetDetaliiComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public informationService: InformationService) {
    super();
    if (data.idSubwaySubdivision != undefined) {
      this.idSubwaySubdivision = data.idSubwaySubdivision;
      
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  observeOnDestroy(): Observable<void> {
    return;
  }

  async ngOnInit() {
    await this.getHighwaySubdivision();
  }

  public async getHighwaySubdivision() {
    this.subdivisionData = await this.informationService.HighwaysSubdivisionsGet(this.idSubwaySubdivision);
  }

  public formatDate(d : Date) : string {
    return d.toString().substring(0, 7);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy(); // Automatically cleans up subscriptions
  }

}
