import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-harta',
  templateUrl: './harta.component.html'
})
export class HartaComponent implements OnInit {

  //srcUrl: string = "https://tapusidaniel.github.io/Autostrazi_in_Romania/"
  srcUrl: string = "/intermap"
  constructor() { }

  ngOnInit(): void {

    let w = window.outerWidth;
        
    let frame = document.getElementById("iframestyle")

    if (frame) {
      if (w <= 600) {
        document.getElementById("iframestyle").style.setProperty('width', '90%');
        document.getElementById("iframestyle").style.setProperty('padding-bottom', '104.25%');
      } else {
        document.getElementById("iframestyle").style.setProperty('width', '70%');
        document.getElementById("iframestyle").style.setProperty('padding-bottom', '40.25%');
      }
    }
  }



}
