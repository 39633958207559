/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";

export const token: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "token"
  ],
  mapper: {
    serializedName: "token",
    type: {
      name: "String"
    }
  }
};
